import React, { useState } from 'react';
import { Form, Row, Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { ChangePasswordAction } from '../../../../redux/actions/changePassword';
const Expiration = () => {
    const token = useSelector(state => state.auth.token)
    const role = useSelector(state => state.auth.role)
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const ChangePasswordFn = (body, token, role) => dispatch(ChangePasswordAction(body, token, role));

    const [validated, setValidated] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            formData()
        }

    };

    const [formdata, setFormData] = React.useState({
        password: '',
        confirmPassword: '',
    })

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }
    const formData = () => {
        // console.log(formdata)
        if (formdata.password === formdata.confirmPassword) {
            let body = {
                newPassword: formdata.password,
            }
            ChangePasswordFn(body, token, role)
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Lo siento',
                text: 'Las contraseñas no coinciden'
            })
        }
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Container>
                <Row className="d-lg-flex align-items-center">
                    <Col xs={12} md={7} lg={5} xl={6} className="justify-content-center pt-5 pt-lg-0">
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                <Form.Label>Nueva contraseña</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Nueva contraseña"
                                    required
                                    name="password"
                                    onChange={readFormData}
                                    style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Nueva contraseña requerida
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                <Form.Label>Confirmar contraseña</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirmar contraseña"
                                    required
                                    name="confirmPassword"
                                    onChange={readFormData}
                                    style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Confirmacion de contraseña requerida
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <button type="submit" className="btn btn-secondary mt-4 w-100">Actualizar</button>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

export default Expiration;