import {
    UPDATE_PASSWORD_START,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
} from './actionsType';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';
import { navigate } from 'gatsby';


export function ChangePasswordAction(formdata, token, role) {
    return (dispatch) => {
        dispatch(ChangePasswordStart())

        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.post('/api/users/update/password', formdata, config)
            .then((res) => {
                const response = res.data;
                // console.log(response)
                dispatch(ChangePasswordSuccess())
                Swal.fire({
                    title: 'Exito!',
                    text: "Contraseña cambiada correctamente!",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (role === "superAdmin") {
                            navigate('/dashboard/clientes')
                        } else {
                            navigate('/dashboard/usuario/cuotas')
                        }
                    }
                })

            })
            .catch(error => {
                dispatch(ChangePasswordFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error, intente de nuevo'
                    })
                }
            })
    }
}
export const ChangePasswordStart = () => {
    return {
        type: UPDATE_PASSWORD_START
    };
};

export const ChangePasswordSuccess = () => ({
    type: UPDATE_PASSWORD_SUCCESS
})

export const ChangePasswordFail = () => ({
    type: UPDATE_PASSWORD_FAIL,
})